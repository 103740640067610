// email constiables
window.emailNewListing = process.env.EMAIL_NEW_LISTING;
window.emailNewUser = process.env.EMAIL_NEW_USER;
window.emailAllocateListing = process.env.EMAIL_ALLOCATE;
window.emailOnEdit = process.env.EMAIL_ON_EDIT;
window.emailCancellation = process.env.EMAIL_CANCELLATION;
window.emailDeallocateListing = process.env.EMAIL_DEALLOCATED_LISTING;
window.deleteUser = process.env.DELETE_USER;
window.emailNonAllocatedListing = process.env.EMAIL_NON_ALLOCATED;
window.emailInvitedNewListing = process.env.EMAIL_NEW_INVITED;
window.emailResendListing = process.env.EMAIL_RESEND_LISTING;
window.emailNewAdmin = process.env.EMAIL_NEW_ADMIN;
window.smsNewListing = process.env.SMS_NEW_LISTING;
window.smsAllocateListing = process.env.SMS_ALLOCATE_LISTING;
window.smsUrgentListing = process.env.SMS_URGENT_LISTING;
window.emailResendInvite = process.env.EMAIL_RESEND_LISTING;
window.emailAdminAllocateListing = process.env.EMAIL_ADMIN_ALLOCATE_LISTING;

// name variables
window.titleName = process.env.COMPANY_VALUE;

// info links
window.infoOne = process.env.INFO_LINK_ONE_USER;
window.infoTwo = process.env.INFO_LINK_TWO;
window.infoThree = process.env.INFO_LINK_THREE;
